// extracted by mini-css-extract-plugin
export var succesStoriesCard = "fL_NS";
export var succesStoriesCardRow = "fL_N2";
export var succesStoriesCard__bottomWrapper = "fL_NY";
export var succesStoriesCard__category = "fL_N0";
export var succesStoriesCard__categoryWrapper = "fL_NZ";
export var succesStoriesCard__description = "fL_NX";
export var succesStoriesCard__image = "fL_NV";
export var succesStoriesCard__locaation = "fL_N1";
export var succesStoriesCard__logosWrapper = "fL_NW";
export var succesStoriesCard__title = "fL_NT";