// extracted by mini-css-extract-plugin
export var homeWebDevProcess = "cl_pT";
export var homeWebDevProcess__between = "cl_pV";
export var homeWebDevProcess__contentFive = "cl_p3";
export var homeWebDevProcess__contentFour = "cl_p2";
export var homeWebDevProcess__contentOne = "cl_pZ";
export var homeWebDevProcess__contentThree = "cl_p1";
export var homeWebDevProcess__contentTwo = "cl_p0";
export var homeWebDevProcess__description = "cl_pY";
export var homeWebDevProcess__image = "cl_pW";
export var homeWebDevProcess__title = "cl_pX";