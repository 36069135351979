// extracted by mini-css-extract-plugin
export var achievementsSlider__badge_1 = "fw_Mc";
export var achievementsSlider__badge_10 = "fw_Mn";
export var achievementsSlider__badge_11 = "fw_Mp";
export var achievementsSlider__badge_12 = "fw_Mq";
export var achievementsSlider__badge_13 = "fw_Mr";
export var achievementsSlider__badge_14 = "fw_Ms";
export var achievementsSlider__badge_15 = "fw_Mt";
export var achievementsSlider__badge_16 = "fw_Mv";
export var achievementsSlider__badge_17 = "fw_Mw";
export var achievementsSlider__badge_18 = "fw_Mx";
export var achievementsSlider__badge_19 = "fw_My";
export var achievementsSlider__badge_2 = "fw_Md";
export var achievementsSlider__badge_3 = "fw_Mf";
export var achievementsSlider__badge_4 = "fw_Mg";
export var achievementsSlider__badge_5 = "fw_Mh";
export var achievementsSlider__badge_6 = "fw_Mj";
export var achievementsSlider__badge_7 = "fw_Mk";
export var achievementsSlider__badge_8 = "fw_Ml";
export var achievementsSlider__badge_9 = "fw_Mm";
export var achievementsSlider__logo = "fw_Mb";
export var achievementsSlider__swiperSliderWrapper = "fw_L9";