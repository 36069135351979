// extracted by mini-css-extract-plugin
export var companyInfo__card = "cD_rt";
export var companyInfo__cardText = "cD_rw";
export var companyInfo__cardTitle = "cD_rv";
export var companyInfo__description = "cD_rs";
export var companyInfo__flex = "cD_rC";
export var companyInfo__head = "cD_rB";
export var companyInfo__headSm = "cD_rz";
export var companyInfo__title = "cD_rr";
export var companyInfo__topWrapper = "cD_ry";
export var companyInfo__wrapper = "cD_rx";